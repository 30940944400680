<template>
  <div>
    <div
      class="fill-height images-preload background-image"
      :class="currentImage"
    >
      <Navbar page="main-page" />
      <div class="px-5 px-sm-0 pb-16">
        <v-container
          class="white--text"
          :style="[
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? { padding: '50px 40px' }
              : { padding: '180px 30px 100px 30px' },
          ]"
        >
          <p class="text-h1 font-weight-bold">
            TODO LISTO <br />PARA TU EVENTO
          </p>
          <p class="text-h3 font-weight-regular mb-10">
            Encuentra todo lo que necesitas para tu evento en un solo click.
          </p>
          <div class="mt-0 pt-0">
            <SearchBar :is-centered="false" />
          </div>
        </v-container>
      </div>
    </div>
    <!-- Providers list -->
    <div class="pt-16 pb-8 secondary--text text-center descent lighten-4">
      <p class="text-h2 font-weight-bold px-5">Proveedores Zaturna</p>
      <p class="text-h3 font-weight-regular mt-n3 px-5">
        Conoce los proveedores más recientes
      </p>
      <ListCards :cards="providers" type="Provider" class="mt-8 mb-1" />
      <v-btn
        plain
        small
        class="pa-0 secondary--text justify-center text-h3 font-weight-regular"
        :ripple="false"
        router
        exact
        :to="{ name: 'Providers' }"
      >
        <span>Ver más</span>
        <v-icon small class="ml-2">fa-chevron-right</v-icon>
      </v-btn>
    </div>
    <div class="py-16">
      <ListCategories
        :zaturna-inspiration="true"
        title="Servicios para todo tipo de eventos"
      />
    </div>
    <div class="py-16 blue-gradient white--text">
      <p class="ma-5 mb-0 mt-md-16 text-h1 font-weight-bold text-center">
        Únete a la red de proveedores Zaturna
      </p>
      <v-container class="px-15 px-md-auto">
        <v-row class="py-10 py-md-15 justify-center">
          <v-col
            cols="12"
            sm="12"
            md="5"
            class="text-center order-2 order-md-1"
          >
            <p class="text-h3 text-md-h3 text-md-left font-weight-bold">
              Ser parte de la industria de organización de eventos es todo un
              reto.
            </p>
            <p
              class="text-body-1 text-md-h4 text-md-left font-weight-regular mb-15"
            >
              Ser proveedor de eventos demanda tener total control sobre
              tiempos, inventario y logística, pero sobre todo un continuo
              esfuerzo hacia la atracción de nuevos clientes.
            </p>
            <Button
              text="Unirme"
              glass
              :horizontal-padding="13"
              aria-label="Ir a la página de información de proveedores"
              url="MainPageProviders"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="5"
            class="mb-6 mb-md-0 text-center order-0 order-md-1"
          >
            <v-img
              :src="require(`@/assets/icons/Solicitudes.svg`)"
              :height="
                $vuetify.breakpoint.xs
                  ? 140
                  : $vuetify.breakpoint.sm
                  ? 200
                  : '100%'
              "
              alt
              contain
              class="icon"
            />
            <!-- <v-icon
              dark
              :size="
                !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm ? 300 : 100
              "
              >far fa-star
            </v-icon> -->
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="ma-5 mb-0 pt-16 secondary--text text-center">
      <p class="text-h1 font-weight-bold">Cobertura Zaturna</p>
      <p class="text-h3 font-weight-regular mt-n3 mb-16">
        ¡Planea a distancia y con confianza!
      </p>
    </div>
    <v-img class="hidden-xs-only" src="@/assets/img/mapamundo.png" />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/LandingPage/Shared/Navbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import ListCards from "@/components/LandingPage/Shared/ListCards.vue";
import ListCategories from "@/components/LandingPage/MainPage/ListCategories.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/LandingPage/Shared/Footer.vue";
import { GET_PROVIDERS_WITHOUT_CURSOR } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";

export default {
  name: "PublicMainPage",
  components: {
    Navbar,
    SearchBar,
    ListCards,
    ListCategories,
    Button,
    Footer,
  },
  data: () => ({
    providers: [],
    providersQuantity: 0,
    providersPerPageQuantity: 4,
    wait: false,
    currentImage: "background-mainpage",
    currentImageIndex: 0,
    images: [
      "background-mainpage",
      "background-mainpage-2",
      "background-mainpage-3",
      "background-mainpage-4",
      "background-mainpage-5",
      "background-mainpage-6",
      "background-mainpage-7",
    ],
  }),
  created() {
    this.fetchProviders();
    setInterval(() => this.changeBackgroundImage(), 8000);
  },
  methods: {
    changeBackgroundImage() {
      if (this.currentImageIndex == this.images.length - 1) {
        this.currentImageIndex = 0;
      } else {
        this.currentImageIndex++;
      }
      this.currentImage = this.images[this.currentImageIndex];
    },
    async fetchProviders() {
      this.wait = await true;
      const { data, errors } = await useQuery(GET_PROVIDERS_WITHOUT_CURSOR, {
        perPage: this.providersPerPageQuantity,
      });
      if (data) {
        this.providers = this.formatProviders(data.providers.providers);
        this.providersQuantity = data.countProviders.providersCount;
      } else if (errors) {
        console.log(errors);
      }
      this.wait = await false;
    },
    formatProviders(providers) {
      const data = providers.map((provider) => {
        const info = {
          ...provider,
          rating:
            provider.providerComment.reduce(
              (total, currentValue) => total + currentValue.score,
              0
            ) / provider.providerComment.length,
          reviews: provider.providerComment.length,
          location: provider.city + ", " + provider.state,
        };
        return info;
      });
      return data;
    },
  },
};
</script>

<style scoped lang="scss">
.images-preload {
  content: url("../../assets/img/02.png") url("../../assets/img/03.png")
    url("../../assets/img/05.png") url("../../assets/img/07.png")
    url("../../assets/img/08.png") url("../../assets/img/09.png")
    url("../../assets/img/10.png");
}

.background-image {
  min-height: 100vh;
  transition: 2s;
}

.background-mainpage {
  background: url("../../assets/img/02.png") no-repeat center center !important;
  background-size: cover !important;
}

.background-mainpage-2 {
  background: url("../../assets/img/03.png") no-repeat center center !important;
  background-size: cover !important;
}

.background-mainpage-3 {
  background: url("../../assets/img/05.png") no-repeat center center !important;
  background-size: cover !important;
}

.background-mainpage-4 {
  background: url("../../assets/img/07.png") no-repeat center center !important;
  background-size: cover !important;
}

.background-mainpage-5 {
  background: url("../../assets/img/08.png") no-repeat center center !important;
  background-size: cover !important;
}

.background-mainpage-6 {
  background: url("../../assets/img/09.png") no-repeat center center !important;
  background-size: cover !important;
}

.background-mainpage-7 {
  background: url("../../assets/img/10.png") no-repeat center center !important;
  background-size: cover !important;
}

.blue-gradient {
  background: linear-gradient(
    180deg,
    var(--v-tertiary-base) 0%,
    var(--v-primary-base) 100%
  );
}

.btn-cristal {
  background: rgba(255, 255, 255, 0.3) !important;
}

.v-btn--active.no-active:not(:focus):not(:hover)::before {
  opacity: 0 !important;
}
</style>
